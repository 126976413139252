@import "../variables.module";
@import "../mixins/mixins.scss";

.search-modal {
  max-width: 800px;
  width: 100%;
  width: 800px;
  min-height: 600px;

  overflow: hidden;
  @media (max-width: 840px) {
    width: 100%;
    min-width: 70vw;
    padding-bottom: 0px;
    margin-top: 0px;
    border-radius: 0px;
    top: 0;
  }

  .search-input-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bottom-container {
    height: 90%;

    .no-results {
      position: relative;
      font-size: 17px;
      margin-top: 30px;
      margin-bottom: 36px;
    }

    @media (max-width: $breakPointTwo) {
      padding: 0px 18px 0px 25px;
    }
  }
}

.item-list-modal-container {
  margin-bottom: 40px;

  .title-carousel-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title-section-container {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 15px;
        font-style: normal;
        // font-weight: 600;
        color: #fff;
      }

      svg {
        fill: $primary-stroke-color;
        width: 20px;

        path {
          fill: $primary-stroke-color;
        }
      }
    }

    .next-prev-container {
      display: flex;
      gap: 10px;

      .arrow-icon {
        @include arrow-icon-style;
      }
    }
  }

  .slots-list-container {
    grid-gap: 10px;
    gap: 10px;
    display: grid;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
}
