@mixin arrow-icon-style {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid rgba(124, 131, 177, 0.2);
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );
    cursor: pointer;
    transition:
      opacity 0.1s,
      border 0.1s,
      background 0.1s;
  
    &.deactive {
      cursor: default;
      opacity: 0.5;
    }
  
    &:not(.deactive):hover {
      border: 1px solid rgba(124, 131, 177, 0.4);
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.4) 0%,
        rgba(94, 103, 158, 0.4) 100%
      );
    }
  }