@import "../variables.module";

.case-open-modal-container {
  border-radius: 6px;
  border: 2px solid $btn-stroke-color;
  background: #262c52;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.sports {
    padding: 20px;
    gap: 15px;
  }

  .sports-icon-container {
    border-radius: 6px;
    border: 2px solid $eightBackground;
    background: $eightBackground;
    padding: 11px;

    svg {
      fill: $inputLabelColorLight;

      path {
        fill: $inputLabelColorLight;
      }
    }
  }

  .case-open-modal-desc {
    display: flex;
    flex-direction: column;
    gap: 8.5px;

    .title {
      color: $fontColor;
      font-size: 15px;
      font-style: normal;
      // font-weight: 600;
      line-height: normal;
    }

    .sports-title {
      color: $inputLabelColorLight;
      font-size: 15px;
      font-style: normal;
      // font-weight: 600;
      line-height: normal;
    }

    span {
      display: flex;
      align-items: center;
      gap: 6px;
      color: $inputLabelColorLight;
      font-size: 14px;
      font-style: normal;
      // font-weight: 600;
      line-height: normal;
    }
  }
}
